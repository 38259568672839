<template>
  <div>
    <v-card v-if="formObject">
      <v-card color="primary" min-height="10" flat tile class="pa-1">
        <v-card-actions class="white--text">
          <v-icon class="mr-2" color="white">mdi-form-select</v-icon>
          {{
            isEditing
              ? `Actualizando el atributo "${formObject.text}"`
              : "Creando un atributo"
          }}
          <v-spacer />
          <v-icon
            large
            @click="closePanel"
            class="mr-1"
            id="btn-close"
            color="white"
            >mdi-close-circle</v-icon
          >
        </v-card-actions>
      </v-card>
      <div class="px-4 py-4">
        <v-card-text class="px-6">
          <v-form v-if="formObject" v-model="valid" ref="form">
            <v-card class="pa-2" color="transparent" flat>
              <v-row align="stretch" justify="start">
                <v-col cols="12" sm="12" md="6">
                  <v-combobox
                    :id="`input-type`"
                    v-model="attributeText"
                    :items="formAttributes"
                    label="Tipo de atributo"
                    :rules="[
                      (v) => !!v || 'El campo tipo de atributo es requerido',
                    ]"
                    outlined
                    :return-object="false"
                    :disabled="isEditing"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="attributeType">
                  <v-combobox
                    :id="`input-operator`"
                    v-model="attributeOperator"
                    :items="attributeType.operators"
                    label="Operador"
                    :rules="[
                      (v) => !!v || 'El campo tipo de atributo es requerido',
                    ]"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row
                align="stretch"
                justify="start"
                v-if="attributeType"
                class="mt-n6"
              >
                <v-col cols="12" sm="12">
                  <v-card flat>
                    <v-card-title>
                      Valores revisados
                      <v-spacer />
                      <v-menu
                        transition="slide-y-transition"
                        :close-on-click="false"
                        :close-on-content-click="false"
                        v-model="isAdding"
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            depressed
                            small
                            color="primary"
                            class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="isAdding = true"
                            :disabled="
                              formObject.attributeValue &&
                                formObject.attributeValue.length > 0 &&
                                !attributeType.multiple
                            "
                            id="btn-add"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-actions>
                            <v-spacer />
                            <v-icon @click="isAdding = false">
                              mdi-close-circle
                            </v-icon>
                          </v-card-actions>
                          <v-form
                            v-model="subValid"
                            ref="subForm"
                            lazy-validation
                            class="pa-4"
                          >
                            <v-row align="stretch" justify="start" class="pa-3">
                              <v-text-field
                                id="input-text"
                                v-model="toAddValue"
                                type="text"
                                v-if="
                                  attributeType.type === 'string' &&
                                    !attributeType.items
                                "
                                label="Texto"
                                :rules="[(v) => !!v || 'Valor requerido']"
                                outlined
                                dense
                              />
                              <v-combobox
                                id="input-text"
                                v-model="toAddValue"
                                v-if="attributeType.items"
                                :items="attributeType.items"
                                label="Seleccione un valor"
                                :rules="[(v) => !!v || 'Valor requerido']"
                                outlined
                                dense
                              />
                              <span v-if="attributeType.type === 'boolean'">
                                <v-card
                                  outlined
                                  class="px-4"
                                  color="transparent"
                                >
                                  <v-radio-group
                                    v-model="toAddValue"
                                    row
                                    v-if="!attributeType.onlyTrue"
                                  >
                                    <v-radio
                                      label="Si"
                                      color="primary"
                                      :value="true"
                                      id="input-radiobtn-true"
                                    />
                                    <v-radio
                                      label="No"
                                      color="primary"
                                      :value="false"
                                      id="input-radiobtn-false"
                                    />
                                  </v-radio-group>
                                  <v-checkbox
                                    v-model="toAddValue"
                                    id="input-checkbox"
                                    label="¿Activar?"
                                    v-else
                                    class="mt-n1"
                                  />
                                </v-card>
                              </span>
                              <v-text-field
                                id="input-number"
                                v-model="toAddValue"
                                type="number"
                                label="Valor númerico"
                                :suffix="attributeType.suffix || ''"
                                v-if="attributeType.type === 'number'"
                                :rules="[(v) => !!v || 'Valor requerido']"
                                outlined
                                dense
                              />
                              <v-date-picker
                                id="input-date"
                                v-model="toAddValue"
                                flat
                                no-title
                                color="accent"
                                v-if="attributeType.type === 'date'"
                              />
                              <v-btn
                                color="success"
                                class="ml-2"
                                @click="addNewValue()"
                                :disabled="
                                  toAddValue === null ||
                                    toAddValue === undefined
                                "
                              >
                                Agregar
                              </v-btn>
                            </v-row>
                          </v-form>
                        </v-card>
                      </v-menu>
                    </v-card-title>
                    <v-card outlined class="pa-2">
                      <span
                        v-if="
                          !formObject.attributeValue ||
                            formObject.attributeValue.length === 0
                        "
                      >
                        <v-card-text>Debe agregar un valor</v-card-text>
                      </span>
                      <span v-else>
                        <v-chip
                          v-for="(attr, i) in formObject.attributeValue"
                          :id="`chip-${i}`"
                          :key="i"
                          close
                          class="mr-2"
                          @click:close="removeAttr(attr)"
                          @click="editAttr(attr)"
                        >
                          <span v-if="attributeType.type === 'date'">
                            {{ attr | date }}
                          </span>
                          <span v-else>
                            {{ attr }}
                          </span>
                        </v-chip>
                      </span>
                    </v-card>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-2 pb-2">
          <v-spacer />
          <v-btn @click="closePanel" class="mr-2" depressed color="transparent"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            @click="isEditing ? updateAttribute() : addAttribute()"
            :disabled="!valid || formObject.attributeValue.length === 0"
          >
            {{ isEditing ? "Actualizar" : "Crear" }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import attributes from "@/assets/json/attributes.json";
import baseForm from "@/shared/utils/Form";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "attribute-form",
  mixins: [baseForm],
  props: ["currentAttributes", "currentTimezone"],
  data: () => ({
    attributeText: "",
    isAdding: false,
    valid: false,
    subValid: false,
    toAddValue: null,
    attributeOperator: null,
    attributeValue: null,
    attributeType: null,
    formAttributes: [],
    textForOperators: {
      "=": "Igual a",
      "!=": "Diferente de",
      ">=": "Mayor o igual",
      "<=": "Menor o igual",
    },
  }),
  watch: {
    attributeText(val) {
      if (val) {
        attributes.forEach((el) => {
          if (el.text === val) {
            this.formObject.attributeCode = el.attributeCode;
            this.formObject.text = val;
            this.attributeType = Object.assign({}, el);
            this.attributeOperator = el.operators[0];
          }
        });
      }
    },
  },
  mounted() {
    this.attributeOperator = {
      value: this.formObject?.operatorCode || "",
      text: this.textForOperators[this.formObject?.operatorCode] || "",
    };
    if (!this.formObject?.attributeValue) {
      this.formObject.attributeValue = [];
    }
    attributes.forEach((attribute) => {
      let count = 0;
      if (attribute.attributeCode === this.formObject.attributeCode) {
        this.formObject.text = attribute.text;
        if (attribute.type === "date") {
          this.formObject.attributeValue = this.formObject.attributeValue.map(
            (val) => {
              val = val.substring(0, 10);
              return val;
            }
          );
        }
      }
      this.currentAttributes.forEach((el) => {
        if (el.attributeCode === attribute.attributeCode) {
          count++;
        }
        if (attribute.text === this.formObject.text) {
          this.formAttributes.push(attribute);
          this.attributeText = attribute.text;
        }
      });
      if (count < attribute.maxTotal) {
        this.formAttributes.push(attribute);
      }
    });
    if (this.currentTimezone) {
      dayjs.tz.setDefault(this.currentTimezone);
    }
  },
  methods: {
    parseDateStringToISO(v) {
      try {
        return dayjs.tz(v);
      } catch (err) {
        console.error(err);
        return v;
      }
    },
    updateAttribute() {
      this.formObject.operatorCode = this.attributeOperator.value;
      this.$emit("updateAttribute", this.formObject);
    },
    addAttribute() {
      this.formObject.operatorCode = this.attributeOperator.value;
      this.$emit("addAttribute", this.formObject);
    },
    addNewValue() {
      if (this.$refs.subForm.validate()) {
        this.toAddValue = this.toAddValue?.value || this.toAddValue;
        if (this.attributeType.type === "date") {
          this.toAddValue = this.parseDateStringToISO(this.toAddValue);
        }
        this.formObject.attributeValue.push(this.toAddValue);
        this.toAddValue = null;
        this.isAdding = false;
      }
    },
    removeAttr(val) {
      this.formObject.attributeValue = this.formObject.attributeValue.filter(
        (el) => el !== val
      );
    },
    editAttr(val) {
      this.toAddValue = val;
      if (this.attributeType.type === "date") {
        this.toAddValue = dayjs(val).format("DD-MM-YYYY");
      }
      this.isAdding = true;
    },
    getTextForAttributes(val) {
      let res = null;
      attributes.forEach((attribute) => {
        if (attribute.attributeCode === val) {
          res = attribute.text;
        }
      });
      return res;
    },
  },
};
</script>
